import cn from 'classnames'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import BtnUpperBorder from './BtnUnderBorder'
import BtnUnderBorder from './BtnUpperBorder'
import BurgerIcon from './BurgerIcon'
import bcg from './bcg.png'
import human from './human.png'
import logo from './logo.svg'
import man from './man.png'
import StartIcon from './startIcon.svg'
import styles from './styles.module.css'
import SupportPopUp from '../../Pages/SupportPopUp/SupportPopUp'

const Header = props => {
	const token = localStorage.getItem('token')
	const navigate = useNavigate()
	const handleSignOut = () => {
		localStorage.clear();
		navigate('/')
	}

	const [Open, setOpen] = useState(false)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const toggleOpen = useCallback(() => setOpen(!Open), [Open])
	const btnSupportHandler= useCallback(()=>setModalIsOpen(!modalIsOpen),[modalIsOpen])

	return (
		<div
			className={cn(styles.header, {
				[styles.lk]: props.lk,
				[styles.map]: props.map,
				[styles.signIn]: props.signIn,
				[styles.signUp]: props.signUp,
				[styles.lection]: props.lection,
			})}
		>
			<div
				className={cn(styles.navBar, {
					[styles.bcg_lk]: props.lk,
					[styles.bcg_map]: props.map,
					[styles.bcg_signIn]: props.signIn,
					[styles.bcg_signUp]: props.signUp,
					[styles.lection]: props.lection,
					[styles.header_close]: !Open,
					[styles.header_open]: Open,
				})}
			>
				<div className={styles.navBar_container}>
					{token && (props.lk || props.map) && (
						<a href='/map' className={styles.link_map} />
					)}
					{props.privatePolitic && (
						<a className={styles.link}>
							<BtnUpperBorder
								styles={styles.btnBorder_up_mediume}
								firstColor={'#EE7D52'}
								secondColor={'#C44819'}
							/>
							<h1
								onClick={props.setModal}
								className={cn(styles.link_text, styles.red)}
							>
								Вернуться
							</h1>
							<BtnUnderBorder
								styles={styles.btnBorder_und_smalle}
								firstColor={'#EE7D52'}
								secondColor={'#C44819'}
							/>
						</a>
					)}
					<a href='/' className={styles.link}>
						<BtnUpperBorder
							styles={styles.btnBorder_up_smalle}
							secondColor={'#A9C453'}
							firstColor={'#778E2D'}
						/>
						<h1 className={cn(styles.link_text, styles.green)}>Главная</h1>
						<BtnUnderBorder
							styles={styles.btnBorder_und_smalle}
							secondColor={'#A9C453'}
							firstColor={'#778E2D'}
						/>
					</a>
					<a href='/about-us' className={styles.link}>
						<BtnUpperBorder
							styles={styles.btnBorder_up_mediume}
							firstColor={'#6092D7'}
							secondColor={'#A3CAFF'}
						/>
						<h1 className={cn(styles.link_text, styles.blue)}>О платформе</h1>
						<BtnUnderBorder
							styles={styles.btnBorder_und_mediume}
							firstColor={'#6092D7'}
							secondColor={'#A3CAFF'}
						/>
					</a>
					<a href='/about-team' className={styles.link}>
						<BtnUpperBorder
							styles={styles.btnBorder_up_mediume}
							firstColor={'#ECB80E'}
							secondColor={'#F0CA67'}
						/>
						<h1 className={cn(styles.link_text, styles.yellow)}>О команде</h1>
						<BtnUnderBorder
							styles={styles.btnBorder_und_mediume}
							firstColor={'#ECB80E'}
							secondColor={'#F0CA67'}
						/>
					</a>
					<a href='/news' className={styles.link}>
						<BtnUpperBorder
							styles={styles.btnBorder_up_smalle}
							firstColor={'#D48028'}
							secondColor={'#F7B169'}
						/>
						<h1 className={cn(styles.link_text, styles.orange)}>Новости</h1>
						<BtnUnderBorder
							styles={styles.btnBorder_und_smalle}
							firstColor={'#D48028'}
							secondColor={'#F7B169'}
						/>
					</a>
					<button onClick={btnSupportHandler} className={styles.link_btn}>
							<BtnUpperBorder
								styles={styles.btnBorder_up_large}
								firstColor={'#4ec802'}
								secondColor={'#0cd137'}
							/>
							<h1 className={cn(styles.link_text, styles.green_light)}>Обратная связь</h1>
							<BtnUnderBorder
								styles={styles.btnBorder_und_large}
								firstColor={'#4ec802'}
								secondColor={'#0cd137'}
							/>
						</button>
					{(props.landing || props.map) &&
						token !== null &&
						token !== undefined && (
							<a href='/home' className={styles.link}>
								<BtnUpperBorder
									styles={styles.btnBorder_up_large}
									firstColor={'#EE7D52'}
									secondColor={'#C44819'}
								/>
								<h1 className={cn(styles.link_text, styles.red)}>
									Личный кабинет
								</h1>
								<BtnUnderBorder
									styles={styles.btnBorder_und_large}
									firstColor={'#EE7D52'}
									secondColor={'#C44819'}
								/>
							</a>
						)}
					{props.signIn && (
						<a href='/sign-up' className={styles.link}>
							<BtnUpperBorder
								styles={styles.btnBorder_up_mediume}
								firstColor={'#EE7D52'}
								secondColor={'#C44819'}
							/>
							<h1 className={cn(styles.link_text, styles.red)}>Регистрация</h1>
							<BtnUnderBorder
								styles={styles.btnBorder_und_mediume}
								firstColor={'#EE7D52'}
								secondColor={'#C44819'}
							/>
						</a>
					)}

					{(props.signUp ||
						(props.landing && (token === null || token === undefined))) && (
						<a href='/sign-in' className={styles.link}>
							<BtnUpperBorder
								styles={styles.btnBorder_up_mediume}
								firstColor={'#EE7D52'}
								secondColor={'#C44819'}
							/>
							<h1 className={cn(styles.link_text, styles.red)}>Авторизация</h1>
							<BtnUnderBorder
								styles={styles.btnBorder_und_mediume}
								firstColor={'#EE7D52'}
								secondColor={'#C44819'}
							/>
						</a>
					)}
					{props.lk && (
						<button onClick={handleSignOut} className={styles.link_btn}>
							<BtnUpperBorder
								styles={styles.btnBorder_up_overSmalle}
								firstColor={'#EE7D52'}
								secondColor={'#C44819'}
							/>
							<h1 className={cn(styles.link_text, styles.red)}>Выход</h1>
							<BtnUnderBorder
								styles={styles.btnBorder_und_overSmalle}
								firstColor={'#EE7D52'}
								secondColor={'#C44819'}
							/>
						</button>
					)}
					{props.map && (
						<button onClick={handleSignOut} className={styles.link_btn}>
							<BtnUpperBorder
								styles={styles.btnBorder_up_overSmalle}
								firstColor={'#603C9D'}
								secondColor={'#A27AE6'}
							/>
							<h1 className={cn(styles.link_text, styles.purple)}>Выход</h1>
							<BtnUnderBorder
								styles={styles.btnBorder_und_overSmalle}
								firstColor={'#603C9D'}
								secondColor={'#A27AE6'}
							/>
						</button>
					)}
					
				</div>
				
			</div>
			{props.bcg && (
				<div
					className={cn(styles.header_container, {
						[styles.mobile_container]: Open,
					})}
				>
					<img src={bcg} alt='' className={styles.header_img} />
					<img
						src={man}
						alt=''
						className={cn(styles.man, { [styles.man_menu]: Open })}
					/>

					<a className={styles.link_logo} href='/'>
						<img src={logo} alt='' className={styles.logo} />
					</a>
				</div>
			)}
			{props.bcgPolitic && (
				<div
					className={cn(styles.header_container, {
						[styles.mobile_container]: Open,
					})}
				>
					<img
						src={man}
						alt=''
						className={cn(styles.man, { [styles.man_menu]: Open })}
					/>
				</div>
			)}
			{props.signIn && (
				<img
					src={human}
					alt=''
					className={cn(styles.man_hover, { [styles.man_menu_second]: Open })}
				/>
			)}
			{props.lk && (
				<a href='/map'>
					<img
						src={StartIcon}
						alt=''
						className={cn(styles.man_hover, { [styles.man_menu_second]: Open })}
					/>
				</a>
			)}
			{props.map && (
				<a href='/home'>
					<img
						src={StartIcon}
						alt=''
						className={cn(styles.man_hover, { [styles.man_menu_second]: Open })}
					/>
				</a>
			)}
			<button
				className={cn(styles.btn_icon, { [styles.btn_fixed]: Open })}
				onClick={toggleOpen}
			>
				<BurgerIcon icon_styles={styles.icon_styles_orange} />
			</button>

			{
				modalIsOpen&& <SupportPopUp close={btnSupportHandler}/>
			}
		</div>
	)
}

export default Header
