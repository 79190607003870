import cn from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import arrow from './arrow.png'
const 	CustomSelectBox = props => {
	const [buttonsVisible, setButtonsVisible] = useState(false)
	const dropdownRef = useRef(null)

	const toggleButtons = useCallback(() => {
		setButtonsVisible(!buttonsVisible)
	}, [setButtonsVisible, buttonsVisible])

	const handleButtonClick = value => {
		props.setValue(value)
		setButtonsVisible(false)
	}
	const closeDropdownOnOutsideClick = event => {
		if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
			return
		}
		setButtonsVisible(false)
	}

	useEffect(() => {
		document.addEventListener('mousedown', closeDropdownOnOutsideClick)
		return () => {
			document.removeEventListener('mousedown', closeDropdownOnOutsideClick)
		}
	}, [])

	return (
		<div className={cn(styles.selectBox, props.selectBoxStyle)} ref={dropdownRef}>
			<div className={styles.field} onClick={props.disable ? toggleButtons : null}>
				{props.name && <label className={styles.field_name}>{props.name}</label>}
				<h1
					className={cn(styles.value, props.valueStyle)}
				>
					{props.value}

				</h1>
				<img src={arrow} alt="arrow" className={cn(styles.arrow, { [styles.arrow_open]: buttonsVisible })} />
			</div>
			{buttonsVisible &&
				props.values !== undefined &&
				props.values.filter(
					item =>
						item.toLowerCase().includes(props.value.toLowerCase()).length !== 0
				) &&
				props.disable && (
					<div className={cn(styles.dropdown, props.dropdownStyle)}>
						<div className={cn(styles.dropdownList, props.dropdownListStyle)}>
							{props.values.map(value => (
								<button
									className={styles.selectBox_btn}
									onClick={() => handleButtonClick(value)}
								>
									{value}
								</button>
							))}
						</div>
					</div>
				)}
		</div>
	)
}

export default CustomSelectBox
