import { useCallback, useEffect, useState, useRef } from 'react'
import axios from 'axios'
import CustomSelectBox from '../../Components/CustomSelectBox/customSelectBox'
import ErrorBox from '../../Components/ErrorBox/errorBox'

import styles from './styles.module.scss'

const proplemsType = [
    'Выдача сертификата',
    'Открытие следующего модуля',
    'Проблемы регистрации',
    'Другое'
]

const SupportPopUp = (props) => {

    const contentRef = useRef(null);
    const commentRef = useRef(null);
    const [formData, setFormData] = useState({
        fio: '',
        currentEmail: localStorage.getItem('email') ? localStorage.getItem('email') : '',
        email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
        type: '',
        comment: '',
    });

    const [errors, setErrors] = useState({
        fio: '',
        email: '',
        type: '',
        comment: '',
    });

    const [isSend, setIsSend] = useState(false)

    const togleAlert = useCallback(() => {
        setIsSend(!isSend)
    }, [isSend])
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const setType = (value) => {
        setFormData(prevData => ({
            ...prevData,
            ['type']: value
        }));
    }

    const clearError = () => {
        setErrors({
            fio: '',
            email: '',
            type: '',
            comment: '',
        })
    }

    const validateField = (fieldName, fieldValue) => {
        switch (fieldName) {
            case 'fio':
                return !fieldValue.trim() ? 'ФИО обязательное поле' : '';
            case 'email':
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return !emailRegex.test(fieldValue) ? 'Некорректный адрес электронной почты' : '';
            case 'type':
                return !fieldValue ? 'Выберите тему обращаения' : '';
            case 'comment':
                return !fieldValue.trim() ? 'Комментарий обязателен' : '';
            default:
                return '';
        }
    };

    const validateAllFields = () => {
        const newErrors = {};
        Object.keys(formData).forEach(key => {
            newErrors[key] = validateField(key, formData[key]);
        });
        setErrors(newErrors);
        return Object.values(newErrors).every(error => !error);
    };

    const sendMail = () => {
        axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/email/send`, {
            ...formData
        })
    }
    const btnSubmitHandler = useCallback(() => {
        if (validateAllFields()) {
            sendMail()
            togleAlert()
        }

    }, [formData]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                props.close();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [props]);

    useEffect(() => { 
        
        commentRef.current.style.height = '40px'
        commentRef.current.style.height = commentRef.current.scrollHeight + "px"
    }, [formData.comment])


    return (
        <div className={styles.container}>
            <div ref={contentRef} className={styles.content}>
                <button
                    onClick={props.close}
                    className={styles.btn_close}>
                    <svg
                        width='32'
                        height='51'
                        viewBox='0 0 32 51'
                        fill='#c2522d'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            d='M24.4556 4.92878C25.5715 2.94392 28.4882 3.13277 29.3389 5.24494C29.6593 6.04055 29.5848 6.94081 29.1379 7.6729L5.01217 47.1966C4.67013 47.757 4.06101 48.0988 3.40453 48.0988C1.96419 48.0988 1.05687 46.5478 1.76274 45.2923L24.4556 4.92878Z'

                        />
                        <path
                            d='M1.06488 7.13531C1.06488 6.65986 1.67988 6.47091 1.94683 6.86435L11.5234 20.9785C12.7929 22.8494 14.202 24.6216 15.7388 26.2799L28.7214 40.289C29.6756 41.3186 29.5845 42.9346 28.5206 43.8504C27.4848 44.742 25.9227 44.6264 25.0295 43.5921L10.1754 26.3922L7.62434 22.4231C5.43056 19.0099 3.688 15.3269 2.44012 11.4662L1.08827 7.28374C1.07277 7.23579 1.06488 7.18571 1.06488 7.13531Z'

                        />
                    </svg>
                </button>
                <p className={styles.title}>Обратная связь</p>
                <div className={styles.form}>
                    <div className={styles.field}>
                        <p className={styles.field_title}>ФИО</p>
                        <input
                            placeholder='Введите ФИО'
                            type="text"
                            name="fio"
                            id="fio"
                            className={styles.field_inp}
                            onChange={handleChange} />
                    </div>
                    <div className={styles.field}>
                        <p className={styles.field_title}>Почта</p>
                        <input
                        
                            placeholder='email'
                            type="email"
                            name="email"
                            id="email"
                            defaultValue={formData.email}
                            className={styles.field_inp}
                            onChange={handleChange} />
                    </div>
                    <div className={styles.field}>

                        <p className={styles.field_title}>Тема</p>
                        <CustomSelectBox
                            dropdownListStyle={styles.dropdownList}
                            dropdownStyle={styles.dropdown}
                            selectBoxStyle={styles.select_box}
                            valueStyle={styles.field_inp}
                            disable={true}
                            values={proplemsType}
                            value={formData.type}
                            setValue={setType}
                        />
                    </div>
                    <div className={styles.field}>
                        <p className={styles.field_title}>Комментарий</p>
                        <textarea
                            placeholder='опишите проблему ...'
                            ref={commentRef}
                            type="comment"
                            name="comment"
                            id="comment"
                            className={styles.field_inp_big}
                            onChange={handleChange} />
                    </div>
                </div>
                <button
                    className={styles.btn_submit}
                    onClick={btnSubmitHandler}>
                    Отправить
                </button>
                {
                    Object.values(errors).some(value => value !== '') &&
                    <ErrorBox
                        text={
                            Object.entries(errors)
                                .find(
                                    ([key, value]) => value !== '')[1]
                        }
                        close={clearError} />
                }
                {isSend && <ErrorBox
                    alert={true}
                    text={
                        'cпасибо, мы приняли к рассмотрению ваш запрос'
                    }
                    close={togleAlert} />
                }
            </div>
        </div>
    )
}

export default SupportPopUp