import cn from 'classnames'
import styles from './styles.module.css'

const ErrorBox = props => {
	return (
		<div className={styles.errorBox_bcg} onClick={props.close}>
			<div className={styles.errorBox}>
				<h1
					className={cn(styles.errorBox_message, props.style, props.textStyle)}
				>
					{props.text}
					{props.alert||!props.warn && ', '}
					
					{props.alert||!props.warn && 'повторите попытку'}
				</h1>

				{props.warn && (
					<button
						onClick={props.exit}
						className={cn(styles.question, props.btnStyle)}
					>
						Да
					</button>
				)}
				<button onClick={props.close} className={styles.errorBox_exit}>
					<svg
						width='32'
						height='51'
						viewBox='0 0 32 51'
						fill='#698B21'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M24.4556 4.92878C25.5715 2.94392 28.4882 3.13277 29.3389 5.24494C29.6593 6.04055 29.5848 6.94081 29.1379 7.6729L5.01217 47.1966C4.67013 47.757 4.06101 48.0988 3.40453 48.0988C1.96419 48.0988 1.05687 46.5478 1.76274 45.2923L24.4556 4.92878Z'
							className={props.style}
						/>
						<path
							d='M1.06488 7.13531C1.06488 6.65986 1.67988 6.47091 1.94683 6.86435L11.5234 20.9785C12.7929 22.8494 14.202 24.6216 15.7388 26.2799L28.7214 40.289C29.6756 41.3186 29.5845 42.9346 28.5206 43.8504C27.4848 44.742 25.9227 44.6264 25.0295 43.5921L10.1754 26.3922L7.62434 22.4231C5.43056 19.0099 3.688 15.3269 2.44012 11.4662L1.08827 7.28374C1.07277 7.23579 1.06488 7.18571 1.06488 7.13531Z'
							className={props.style}
						/>
					</svg>
				</button>
			</div>
		</div>
	)
}

export default ErrorBox
